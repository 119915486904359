import { config } from '../config';
import { animate, animate_delay } from '../helpers/animate';
import { css } from '../helpers/css';
import { debug } from '../helpers/debug';
import { check_surface } from '../helpers/surface';
import { mouseover as event_mouseover } from '../events/mouseover';
import { userabo } from '../helpers/userabo';
import { preroll_unsold } from '../helpers/preroll';

// Mode de fixation de la pub
const sticky = {
  // active le sticky sur une pub
  set_fixed: id => {
    const { anonymousOnly, unsold } = config.sticky;
    // Si la surface de la pub est trop grande on ne poursuit pas
    if (!check_surface(id)) return;
    
    if ( unsold && preroll_unsold() && !userabo()) {
      return;
    } else {
      if (anonymousOnly && userabo()) return;
    }
    
    
    debug('tag', id, 'set fixed');
    const { element } = config.ads[id];

    // On donne la hauteur de la pub au parent pour empêcher les décalages quand elle sera sticky
    css(element.parentNode, {
      minHeight: element.offsetHeight + 'px'
    });

    animate(id, element);
    animate_delay(id, element);

    // TODO: ajout d'une croix de fermeture dans le cas où la pub resterai bloquée
    // pseudo-element?

    // Permet de garder la pub affichée si la souris la survole
    event_mouseover(id);
  },

  // Désactive le sticky d'une pub
  set_unfixed: (id, delay = false, force = false) => {
    // Si la surface de la pub est trop grande on ne poursuit pas
    if (!check_surface(id)) return;

    const {[id]:ad} = config.ads;
    delay = (delay !== false) ? delay : ad.delay;

    // désactive les autres timer
    ad.timeout.forEach(timeout => {
      clearTimeout(timeout);
    });

    debug('tag', id, 'set unfixed');
    animate_delay(id, ad.element, delay, force);
  },

  add_close: id => {
    // css(config.ads[id].element);
  }
}

export { sticky };
