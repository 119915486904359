import { config } from '../config';
import { debug } from '../helpers/debug';

// Vérifie si la surface de la pub est plus petite que 30% de l'écran
const preroll_unsold = () => {
    const w = window.innerWidth;
    let unsold = false;

    const setTopBannerSticky = () => {
        const banner = 
            document.querySelector('.hp_banniere_haute_es') || 
            document.querySelector('.article_banniere_haute') ||
            document.querySelector('.hdr-hor-m-1') || 
            document.querySelector('.article_banniere_haute_es');
    
        if (banner) {
            banner.style.position = "sticky";
            banner.style.zIndex = 1000;
            banner.style.top = 0;
        }
    }
    
    if (w < 663) {
        window.addEventListener("message", function (event) {
            if (event.origin === "https://www.ultimedia.com" && event.data === "event=UnSold") {
                unsold = true;
                setTopBannerSticky()
            }
        }, false)
    }

  if (!unsold) debug('warning', 'Preroll active');

  return unsold;
}

export { preroll_unsold };
