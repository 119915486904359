// Config par défaut
const config = {
  actions: {
    // action_name: {
    //   mode: sticky
    //   position: ['bottom', 'bottom', 'bottomright', 'bottomright'],
    //   maxsize: [[300, 250], [300, 250], [300, 600], [300, 600]],
    //   slot: {
    //     cible: '',
    //     formats: [[300, 600],[300, 250]],
    //     name: 'rossel-dynamic-',
    //     number: 1,
    //     path: '/6355419/Travel'
    //   },
    // }
  },
  ads: {
    // delay: integer
    // _element: dom element,
    // _ghost: integer,
    // _hover: true|false,
    // mode: slider|sticky|swap|exclude,
    // maxheight: integer,
    // _parent: dom element,
    // preload: boolean,
    // position: top|bottom|left|right|bottomleft|bottomright|topleft|topright,
    // profile: leaderboard|sidebar,
    // _size: {
    //   height: ad height,
    //   surface: ad surface,
    //   width: ad width
    // },
    // sticky: onposition|onload,
    // swap: onposition|onload,
    // timeout: [],
    // type: ad|redac|overlay,
    // transition: slide|fade|none
    // _viewable: true|false,
    // _zone: dom element,
  },
  analytics: {
    active: false,
    send: 'send',
    event: 'event',
    category: 'viewtiful'
  },
  breakpoints: [
    '(max-width: 699px)',
    '(min-width: 700px) and (max-width: 991px)',
    '(min-width: 992px) and (max-width: 1199px)',
    '(min-width: 1200px)',
  ],
  browsers: {
    exclude: ['MSIE'],
    minimal: []
  },
  _date: +new Date(),
  _debug: {
    count: 0,
    log: [],
    timeout: false
  },
  default: {
    profile: 'exclude'
  },
  mobile: {
    breakpoint: 0,
    maxheight: 100
  },
  _load: [],
  offset: {
    bottom: [0, 0, 0, 0],
    left: [0, 0, 0, 0],
    right: [0, 0, 0, 0],
    top: [0, 0, 0, 0]
  },
  onclick: false,
  onleave: false,
  onvisibility: false,
  profile: {
    leaderboard: {
      delay: 500,
      mode: 'sticky',
      position: 'top',
      transition: 'slide',
    },
    sidebar: {
      delay: 1000,
      mode: 'sticky',
      position: 'top',
      transition: 'fade',
    },
    exclude: {
      mode: 'exclude'
    }
  },
  _scroll: {
    direction: 'bottom',
    last: 0
  },
  _stuck: false,
  dataLayer: {
    abo: window.dataLayer[0].member_status_v2 || window.dataLayer[0].typeUser,
  },
  sticky: {
    mode: 'onposition', // onposition|onload
    anonymousOnly: false,
    unsold: false,
    parent: [],
    style: {
      background: '#fff'
    }
  },
  swap: {
    _blocks: [],
    class: '',
    mode: 'onposition', // onposition|onload
    max: 3,
    style: {},
    zones: null
  },
  timeout: 3000,
  trustAds: true,
  _window: {
    width: window.innerWidth,
    height: window.innerHeight,
    surface: window.innerWidth * window.innerHeight,
    page: document.querySelector('html')
  }
}

export { config };
