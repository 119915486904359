import { config } from '../config';

const userabo = id => {
    const { abo } = config.dataLayer;
    let check = (abo === "abonne" || abo === "Connected") ? true : false;
  
    return check;
}

export { userabo };
